export default {
	prefix: 'isd',
	iconName: 'dropdown-oben',
	niceName: 'dropdown-oben',
	icon: [
		58,
		58,
		[],
		'ff12',
		'M28.62.679c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41-15.69 0-28.41-12.72-28.41-28.41S12.93.679 28.62.679zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91c-.017-14.303-11.607-25.893-25.91-25.91zm0 10.33c.382 0 .724.171.953.441L41.89 26.27a1.24 1.24 0 010 1.76 1.21 1.21 0 01-.88.37 1.25 1.25 0 01-.89-.37L29.87 17.778v25.641a1.25 1.25 0 01-2.5 0V17.778L17.023 28.127a1.25 1.25 0 01-1.77-1.76l12.402-12.403c.23-.278.577-.455.965-.455z'
	]
};