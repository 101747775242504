export default {
	prefix: 'isd',
	iconName: 'objektleitung',
	niceName: 'objektleitung',
	icon: [
		49,
		66,
		[],
		'ff40',
		'M9.62 8.888a1.25 1.25 0 110 2.5h-6.5v51.75h43v-51.75h-6.36a1.25 1.25 0 110-2.5h7.61c.69 0 1.25.56 1.25 1.25v54.25c0 .69-.56 1.25-1.25 1.25H1.87c-.69 0-1.25-.56-1.25-1.25v-54.25c0-.69.56-1.25 1.25-1.25zm25.63 19.18c.457-.005.88.24 1.1.64l6.55 12a1.25 1.25 0 010 1.23 1.27 1.27 0 01-1.08.62h-2.95v6.94a1.24 1.24 0 01-1.25 1.25 1.25 1.25 0 01-1.17-1.25v-6.94h-8.33v7.93a1.25 1.25 0 11-2.5 0v-7.89h-2.58a1.25 1.25 0 01-1.1-.65l-3.15-5.72-3.83 5.81v7.29a1.25 1.25 0 11-2.5 0v-6.77H8.82a1.25 1.25 0 01-1.05-1.93l7.61-11.97a1 1 0 01.13-.16.43.43 0 01.11-.12 1.24 1.24 0 01.83-.31zm-18.88 3.71l-5.27 8.26h2.14l4.19-6.34-1.06-1.92zm18.25-1.17h-16l5.16 9.43h15.93l-5.09-9.43zM24.615.42a6.54 6.54 0 016.405 5.218h4.17c.69 0 1.25.56 1.25 1.25v6.86c0 .69-.56 1.25-1.25 1.25h-11a1.25 1.25 0 110-2.5h9.84v-4.36h-4.07a1.26 1.26 0 01-1.25-1.22 4.045 4.045 0 00-8.09 0 1.24 1.24 0 01-1.3 1.22h-4.06v4.4h4.2a1.25 1.25 0 010 2.5h-5.45a1.25 1.25 0 01-1.25-1.29v-6.86c0-.69.56-1.25 1.25-1.25h4.2A6.54 6.54 0 0124.615.42z'
	]
};