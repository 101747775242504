export default {
	prefix: 'isd',
	iconName: 'kundenportal',
	niceName: 'kundenportal',
	icon: [
		77,
		50,
		[],
		'ff55',
		'M32.142 40.898c.36 0 .704.157.94.43l.66.77h9.73l.67-.77a1.21 1.21 0 01.94-.43h30a1.27 1.27 0 011 .46c.238.298.33.687.25 1.06-.07.27-1.54 6.69-6.66 6.69h-62.1c-4.727 0-6.351-5.47-6.62-6.524l-.027-.108a1.25 1.25 0 011.217-1.578zm-.58 2.46H3.922c.6 1.38 1.75 3.21 3.7 3.21h62.05c1.95 0 3.1-1.79 3.7-3.21h-27.7l-.66.76a1.24 1.24 0 01-.94.44h-10.9a1.26 1.26 0 01-.95-.44l-.66-.76zm22.92-18.39a6.15 6.15 0 016.14 6.13v6.52a1.25 1.25 0 01-1.29 1.25 1.24 1.24 0 01-1.25-1.25v-6.52a3.64 3.64 0 00-3.64-3.63h-7.07a3.64 3.64 0 00-3.64 3.63v6.52a1.25 1.25 0 11-2.5 0v-6.52a6.15 6.15 0 016.14-6.13zm-27.29-18.82c.69 0 1.25.56 1.25 1.25v3.17h2.18a1.25 1.25 0 011.2 1.27v2.73h1.33c.67 0 1.223.53 1.25 1.2v3.26h2.07c.69 0 1.25.56 1.25 1.25v17.34a1.25 1.25 0 11-2.5 0v-16.05h-16.1v16.05a1.25 1.25 0 11-2.5 0v-17.34a1.25 1.25 0 011.28-1.21h14v-2h-10.67a1.25 1.25 0 010-2.5h8.09v-1.5h-5.51a1.25 1.25 0 010-2.5h2.13v-3.17c0-.69.56-1.25 1.25-1.25zm36.67-6.15a5.58 5.58 0 015.57 5.57v32a1.25 1.25 0 11-2.5 0v-32a3.08 3.08 0 00-3.07-3h-50.49a3.07 3.07 0 00-3.06 3v32.05a1.25 1.25 0 11-2.5-.05v-32a5.58 5.58 0 015.56-5.57zm-32.14 36.37a1.25 1.25 0 110 2.5h-9.1a1.25 1.25 0 010-2.5zm24.04-5.05c.69 0 1.25.56 1.25 1.25v5a1.25 1.25 0 11-2.5 0v-5c0-.69.56-1.25 1.25-1.25zm-9.71 0c.69 0 1.25.56 1.25 1.25v5a1.25 1.25 0 11-2.5 0v-5c0-.69.56-1.25 1.25-1.25zm-14.33-.39a1.25 1.25 0 110 2.5h-9.1a1.25 1.25 0 010-2.5zm0-5.45a1.25 1.25 0 110 2.5h-9.1a1.25 1.25 0 010-2.5zm19.19-11.41a4.93 4.93 0 110 9.86 4.93 4.93 0 010-9.86zm0 2.5a2.43 2.43 0 100 4.86 2.43 2.43 0 000-4.86z'
	]
};