export default {
	prefix: 'isd',
	iconName: 'vorteile',
	niceName: 'vorteile',
	icon: [
		47,
		61,
		[],
		'ff4f',
		'M41.29.387a5.26 5.26 0 015.25 5.25v49.78a5.26 5.26 0 01-5.25 5.25H5.95a5.26 5.26 0 01-5.25-5.25v-44.09a1.24 1.24 0 01.44-.94L12.3.697a1.25 1.25 0 01.82-.31zm0 2.5H16.17v11.89c0 .69-.56 1.25-1.25 1.25H7.49a1.25 1.25 0 010-2.5h6.18V2.897h-.08l-10.39 9v43.49a2.76 2.76 0 002.75 2.75h35.34a2.76 2.76 0 002.75-2.75V5.637a2.76 2.76 0 00-2.75-2.75zm-26.22 26.64c.69 0 1.25.56 1.25 1.25v14.93c0 .69-.56 1.25-1.25 1.25h-4.3a1.25 1.25 0 010-2.5h3.05v-12.43h-2.67a1.25 1.25 0 010-2.5zm20.17 2.36a1.25 1.25 0 012.46.45 3.27 3.27 0 01-1.53 2.3h.09a1.23 1.23 0 011 1.45 3.24 3.24 0 01-1.73 2.33c.583.194.936.785.83 1.39a3.25 3.25 0 01-1.6 2.26h.08a1.24 1.24 0 01.62 1.65 3.6 3.6 0 01-3.37 2.24H18.31a1.25 1.25 0 010-2.5h13.78a1.1 1.1 0 001.09-.77 1.57 1.57 0 01.19-.26h-.96a1.25 1.25 0 010-2.5h.69a.7.7 0 00.8-.62c.052-.235.17-.45.34-.62h-.93a1.25 1.25 0 010-2.5H34a.69.69 0 00.8-.62 1.2 1.2 0 01.35-.62 4.62 4.62 0 01-.66.06h-.74a1.25 1.25 0 010-2.5h.69a.69.69 0 00.8-.62zm-10.221-11.47a3.3 3.3 0 013.141.25 3.34 3.34 0 011.27 2.51 17.47 17.47 0 01-.32 5.5h5a1.25 1.25 0 010 2.5h-6.75a1.24 1.24 0 01-1.11-1.81c1.056-2.121.752-5.505.714-5.885l-.004-.035c-.04-.47-.16-.69-.26-.76a.52.52 0 00-.27-.07h-.27c-.18 0-.47 0-.54.36a9.75 9.75 0 01-6.88 9.23 1.29 1.29 0 01-.43.07l.02.06a1.25 1.25 0 01-.42-2.42 7.23 7.23 0 005.19-7 3.3 3.3 0 011.919-2.5z'
	]
};