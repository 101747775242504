export default {
	prefix: 'isd',
	iconName: 'standort',
	niceName: 'standort',
	icon: [
		45,
		65,
		[],
		'ff46',
		'M22.62.16c12.355 0 22.37 10.015 22.37 22.37 0 11.35-5.87 21.92-10.79 28.79a80.29 80.29 0 01-10.75 12.26l-.83.74-.83-.74a80.29 80.29 0 01-10.75-12.26C6.12 44.45.25 33.88.25 22.53.25 10.175 10.265.16 22.62.16zm0 2.5c-10.97.011-19.859 8.9-19.87 19.87 0 18.15 16 34.68 19.87 38.41.193-.187.416-.405.667-.654l.313-.314c5.043-5.087 18.89-20.581 18.89-37.442-.011-10.97-8.9-19.859-19.87-19.87zm0 5.95c7.688 0 13.92 6.232 13.92 13.92-.01 7.578-6.067 13.738-13.605 13.916l-.315.004c-7.688 0-13.92-6.232-13.92-13.92S14.932 8.61 22.62 8.61zm0 2.5c-6.307 0-11.42 5.113-11.42 11.42 0 6.307 5.113 11.42 11.42 11.42 6.307 0 11.42-5.113 11.42-11.42-.006-6.305-5.115-11.414-11.42-11.42z'
	]
};