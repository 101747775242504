import Vue from 'vue';

import {
  TabsPlugin,
  VBVisiblePlugin,
  VBHoverPlugin,
  CollapsePlugin,
  DropdownPlugin,
  NavbarPlugin,
  ButtonPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormSelectPlugin,
  CardPlugin,
  PaginationPlugin,
  InputGroupPlugin,
  ListGroupPlugin,
  BadgePlugin,
  FormCheckboxPlugin,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue';

Vue.use(TabsPlugin);
Vue.use(VBVisiblePlugin);
Vue.use(VBHoverPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(NavbarPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormSelectPlugin);
Vue.use(CardPlugin);
Vue.use(PaginationPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ListGroupPlugin);
Vue.use(BadgePlugin);
Vue.use(FormCheckboxPlugin);

Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BButton', BButton);
