export default {
	prefix: 'isd',
	iconName: 'corporate-benefits',
	niceName: 'corporate-benefits',
	icon: [
		67,
		59,
		[],
		'ff0d',
		'M55.42 33.449a1.25 1.25 0 012 1.46l-2.74 3.8h11.84v7.76l-13.01.04v9h8.92v-6.47a1.25 1.25 0 012.5 0v9H39.6v-9a1.25 1.25 0 012.5 0v6.43H51v-9H38v-7.76h11.87l-2.74-3.8a1.25 1.25 0 012-1.46l3.13 4.34zM27.2.569a6.53 6.53 0 016.53 6.53 16.43 16.43 0 01-1.24 4.72 19.78 19.78 0 014.43-3.84 6.55 6.55 0 018.69 3.15 6.48 6.48 0 01.22 5 6.6 6.6 0 01-1.39 2.25h9.29v11.79h-21.5v25.34h4a1.25 1.25 0 010 2.5H4.12v-24.19a1.25 1.25 0 012.5 0v21.69h15.6v-25.34H.72v-11.79h9.3a6.47 6.47 0 01-1.4-2.25 6.53 6.53 0 018.86-8.15l.449.292a19.52 19.52 0 013.981 3.548 16.35 16.35 0 01-1.24-4.72A6.53 6.53 0 0127.2.569zm2.48 20.34h-4.96l-.04 34.6h5v-34.6zm34.34 20.3H53.51v2.76h10.51v-2.76zm-13.01 0H40.5v2.76h10.51v-2.76zm.17-20.3H32.23v6.76h19l-.05-6.76zm-29-.03H3.22v6.79l18.96-.03v-6.76zm18.889-10.738a4 4 0 00-3.089.148c-1.43.62-5.12 4.71-7.93 8.14h3.58c3.42-.09 6.71-.33 7.77-.83a4 4 0 001.94-5.36 4 4 0 00-2.271-2.098zM14.72 9.869a4 4 0 00-1.71 7.68c1.08.51 4.54.74 8.05.83h3.29c-.518-.623-1.065-1.27-1.623-1.914l-.48-.552c-2.328-2.66-4.736-5.163-5.827-5.674a4.11 4.11 0 00-1.7-.37zm12.48-6.8a4 4 0 00-4 4c0 1.31 1.5 5.35 4 10.66 2.47-5.32 4-9.36 4-10.66a4 4 0 00-4-4z'
	]
};