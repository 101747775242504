export default {
	prefix: 'isd',
	iconName: 'kompetenzcenter-akademie',
	niceName: 'kompetenzcenter-akademie',
	icon: [
		75,
		53,
		[],
		'ff2b',
		'M56.14.728a1.24 1.24 0 011.59 1.2v48.57h3.31v-4.63a1.25 1.25 0 012.5 0v4.63h3.58v-27.33l-6.73-2a1.25 1.25 0 01.69-2.4l7.63 2.21c.54.15.912.641.91 1.2v28.32h4a1.25 1.25 0 010 2.5h-72a1.25 1.25 0 110-2.5H5.5v-28.37c0-.555.367-1.044.9-1.2l7.63-2.21a1.25 1.25 0 01.7 2.4l-6.73 2v27.38h3.53v-4.63a1.25 1.25 0 012.5 0v4.63h3.31v-37.57a1.26 1.26 0 01.9-1.2zm-13.31 43.19H32.24v6.58h4.09v-4.36a1.25 1.25 0 012.5 0v4.36h4v-6.58zm12.4-40.32l-35.39 10.27v36.63h9.9v-6.58h-2.31a1.25 1.25 0 110-2.5h20.19a1.25 1.25 0 010 2.5h-2.29v6.58h9.9v-46.9zm-42.45 31.17c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32-1.8a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zm-38.19-8.31c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32 1.09a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zm0-7.23a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5z'
	]
};