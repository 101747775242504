export default {
	prefix: 'isd',
	iconName: 'arbeitsschutz',
	niceName: 'arbeitsschutz',
	icon: [
		71,
		64,
		[],
		'ff06',
		'M70.168 35.657a5.88 5.88 0 01-2.73 7.83l-25.22 13.52c-.092.05-.19.09-.29.12l-1.29.33h-.27l-23.34.63a15.78 15.78 0 00-6 4.55c-.225.28-.561.448-.92.46a1.24 1.24 0 01-.94-2.07c3.379-3.828 6.332-5.03 7.088-5.292l.09-.03.092-.028a1.57 1.57 0 01.3-.05l23.39-.63 1-.26 25.13-13.45a3.39 3.39 0 001.7-4.45c-.77-1.43-2.77-1.8-4.54-.85l-12.18 6.23a1.252 1.252 0 11-1.14-2.23l12.15-6.21c3-1.61 6.48-.79 7.92 1.88zm-45.98 1.26a51.36 51.36 0 008.22.91h9.23c3.45 0 6.25 2.52 6.25 5.61 0 3.09-2.8 5.62-6.25 5.62h-12.81a1.25 1.25 0 010-2.5h12.81c2.07 0 3.75-1.4 3.75-3.12 0-1.72-1.75-3.13-3.75-3.13h-9.27a52.64 52.64 0 01-8.87-1c-.11 0-5.69-1.71-10.31 1.35l-10.55 9.75a1.24 1.24 0 01-.89.33 1.25 1.25 0 01-.85-2.17l10.67-9.82.15-.12c5.65-3.81 12.19-1.82 12.47-1.71zm16.16-18.54c5.43 0 8.67 2.66 8.67 7.11v4.89a1.25 1.25 0 01-2.5 0v-4.89c0-3.57-2.88-4.49-5.59-4.57l-4.16 4.93a1.27 1.27 0 01-.93.44 1.28 1.28 0 01-1-.41l-4.51-5c-5.18.206-5.278 3.358-5.28 4.525v4.955a1.25 1.25 0 01-2.5 0v-4.87c0-4.52 3-7.11 8.33-7.11.353 0 .688.153.92.42l3.93 4.32 3.62-4.29a1.27 1.27 0 011-.45zM35.808.387a6.78 6.78 0 016.78 6.78v2.08a6.83 6.83 0 01-6.78 6.87 6.83 6.83 0 01-6.78-6.87v-2.08a6.78 6.78 0 016.78-6.78zm0 2.42a4.33 4.33 0 00-4.28 4.36v2.08a4.28 4.28 0 008.56 0v-2.08a4.33 4.33 0 00-4.28-4.36z'
	]
};