export default {
	prefix: 'isd',
	iconName: 'news-liste-rechts',
	niceName: 'news-liste-rechts',
	icon: [
		59,
		58,
		[],
		'ff3d',
		'M29.62.069c15.878 0 28.75 12.872 28.75 28.75-.022 15.87-12.88 28.728-28.75 28.75-15.878 0-28.75-12.872-28.75-28.75S13.742.069 29.62.069zm0 2.5c-14.497 0-26.25 11.753-26.25 26.25s11.753 26.25 26.25 26.25 26.25-11.753 26.25-26.25c-.022-14.488-11.762-26.228-26.25-26.25zm-4.1 10.39l15 15c.238.23.371.549.37.88 0 .334-.134.654-.37.89l-15 15a1.25 1.25 0 01-.9.35 1.29 1.29 0 01-.89-.37 1.25 1.25 0 010-1.77l14.13-14.12-14.11-14.09a1.252 1.252 0 011.77-1.77z'
	]
};