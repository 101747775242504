import akademie from './akademie';
import akkordeonMinus from './akkordeon-minus';
import akkordeonPlus from './akkordeon-plus';
import arbeitenInDenNl from './arbeiten-in-den-nl';
import arbeitenInDenObjekten from './arbeiten-in-den-objekten';
import arbeitsmaterialien from './arbeitsmaterialien';
import arbeitsschutz from './arbeitsschutz';
import aussenreinigung from './aussenreinigung';
import bankenVersicherungen from './banken-versicherungen';
import berufsbilder from './berufsbilder';
import bewerbungsverfahren from './bewerbungsverfahren';
import branchenloesungen from './branchenloesungen';
import bueroreinigung from './bueroreinigung';
import corporateBenefits from './corporate-benefits';
import dasUnternehmen from './das-unternehmen';
import dienstleistungen from './dienstleistungen';
import downloadBild from './download-bild';
import downloadPdf from './download-pdf';
import dropdownOben from './dropdown-oben';
import dropdownUnten from './dropdown-unten';
import dualesStudium from './duales-studium';
import einzelGrosshandel from './einzel-grosshandel';
import facebook from './facebook';
import faq from './faq';
import fuerBewerber from './fuer-bewerber';
import fuerUnternehmen from './fuer-unternehmen';
import garten from './garten';
import gesellschaftlichesEngagement from './gesellschaftliches-engagement';
import gesundheitsmanagement from './gesundheitsmanagement';
import gruenflaechenpflege from './gruenflaechenpflege';
import grundstuecksWohnungswaltung from './grundstuecks-wohnungswaltung';
import handelskundenFlaechenkundenFilialunternehmen from './handelskunden-flaechenkunden-filialunternehmen';
import haustechnikHausmeisterService from './haustechnik-hausmeister-service';
import haustechnik from './haustechnik';
import ihreMoeglichkeiten from './ihre-moeglichkeiten';
import ihreVorteile from './ihre-vorteile';
import industrieLogistik from './industrie-logistik';
import initiativbewerbung from './initiativbewerbung';
import isd2go from './isd2go';
import jetztAnrufen from './jetzt-anrufen';
import jobboerse from './jobboerse';
import jobsuche from './jobsuche';
import kindergaerten from './kindergaerten';
import kompetenzcenterAkademie from './kompetenzcenter-akademie';
import kompetenzcenter from './kompetenzcenter';
import kontaktStandorte from './kontakt-standorte';
import kontaktformular from './kontaktformular';
import kooperationspartner from './kooperationspartner';
import kundenportal from './kundenportal';
import kununu from './kununu';
import linkedin from './linkedin';
import login from './login';
import magazin from './magazin';
import mail from './mail';
import management from './management';
import medicalServiceCenter from './medical-service-center';
import mehr from './mehr';
import meldungen from './meldungen';
import mitarbeiterbegleitung from './mitarbeiterbegleitung';
import mitarbeiterzufriedenheit from './mitarbeiterzufriedenheit';
import mobileTaetigkeitsnachweise from './mobile-taetigkeitsnachweise';
import navigationAusklappen from './navigation-ausklappen';
import newsListeLinks from './news-liste-links';
import newsListeRechts from './news-liste-rechts';
import niederlassungenKontakt from './niederlassungen-kontakt';
import niederlassungen from './niederlassungen';
import objektleitung from './objektleitung';
import qualitaetsversprechen from './qualitaetsversprechen';
import reinigung from './reinigung';
import scrollen from './scrollen';
import sliderLinks from './slider-links';
import sliderRechts from './slider-rechts';
import standort from './standort';
import suchen from './suchen';
import teaserAusklappen from './teaser-ausklappen';
import treppenhausreinigung from './treppenhausreinigung';
import ueberUnsOne from './ueber-uns-one';
import ueberUnsTwo from './ueber-uns-two';
import umweltschutz from './umweltschutz';
import unternehmensgeschichte from './unternehmensgeschichte';
import verbandstagungen from './verbandstagungen';
import versicherungen from './versicherungen';
import vorteile from './vorteile';
import weiterbildung from './weiterbildung';
import winterdienst from './winterdienst';
import xing from './xing';
import zahlenDatenFakten from './zahlen-daten-fakten';

export default {
	akademie,
	akkordeonMinus,
	akkordeonPlus,
	arbeitenInDenNl,
	arbeitenInDenObjekten,
	arbeitsmaterialien,
	arbeitsschutz,
	aussenreinigung,
	bankenVersicherungen,
	berufsbilder,
	bewerbungsverfahren,
	branchenloesungen,
	bueroreinigung,
	corporateBenefits,
	dasUnternehmen,
	dienstleistungen,
	downloadBild,
	downloadPdf,
	dropdownOben,
	dropdownUnten,
	dualesStudium,
	einzelGrosshandel,
	facebook,
	faq,
	fuerBewerber,
	fuerUnternehmen,
	garten,
	gesellschaftlichesEngagement,
	gesundheitsmanagement,
	gruenflaechenpflege,
	grundstuecksWohnungswaltung,
	handelskundenFlaechenkundenFilialunternehmen,
	haustechnikHausmeisterService,
	haustechnik,
	ihreMoeglichkeiten,
	ihreVorteile,
	industrieLogistik,
	initiativbewerbung,
	isd2go,
	jetztAnrufen,
	jobboerse,
	jobsuche,
	kindergaerten,
	kompetenzcenterAkademie,
	kompetenzcenter,
	kontaktStandorte,
	kontaktformular,
	kooperationspartner,
	kundenportal,
	kununu,
	linkedin,
	login,
	magazin,
	mail,
	management,
	medicalServiceCenter,
	mehr,
	meldungen,
	mitarbeiterbegleitung,
	mitarbeiterzufriedenheit,
	mobileTaetigkeitsnachweise,
	navigationAusklappen,
	newsListeLinks,
	newsListeRechts,
	niederlassungenKontakt,
	niederlassungen,
	objektleitung,
	qualitaetsversprechen,
	reinigung,
	scrollen,
	sliderLinks,
	sliderRechts,
	standort,
	suchen,
	teaserAusklappen,
	treppenhausreinigung,
	ueberUnsOne,
	ueberUnsTwo,
	umweltschutz,
	unternehmensgeschichte,
	verbandstagungen,
	versicherungen,
	vorteile,
	weiterbildung,
	winterdienst,
	xing,
	zahlenDatenFakten,
};
