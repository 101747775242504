export default {
	prefix: 'isd',
	iconName: 'download-pdf',
	niceName: 'download-pdf',
	icon: [
		43,
		57,
		[],
		'ff11',
		'M41.73.707c.69 0 1.25.56 1.25 1.25v53c0 .69-.56 1.25-1.25 1.25H1.51c-.69 0-1.25-.56-1.25-1.25v-38.38a1.15 1.15 0 01.1-.48 1.09 1.09 0 01.27-.4l14.63-14.62c.122-.11.26-.201.41-.27a1.29 1.29 0 01.48-.1zm-1.25 2.5H17.4v13.37c0 .69-.56 1.25-1.25 1.25H2.78v35.88h37.7v-50.5zm-20.39 16.98c.12.01 2.95.25 3.12 4.47.05 1.55-.22 3.34-1.52 6.8a43.68 43.68 0 003.74 4.65l1.48 1.6c4.93-.21 7.32.84 8.26 1.42l.166.099a3.26 3.26 0 011.294 3.901c-.32.9-1.73 2.49-4.61 1.72-1.58-.42-2.69-1.01-6.13-4.61a43 43 0 00-5 .71 39.51 39.51 0 00-3.88 1c-2.52 4.93-4.61 7.77-6.24 8.46a3.55 3.55 0 01-1.38.3 2.93 2.93 0 01-2.61-1.67c-.33-.58-1.15-2.46 1.19-4.79.054-.055.11-.113.17-.172l.185-.187c1.04-1.03 2.88-2.612 6.925-3.981.57-1.18 1.1-2.38 1.87-4s1.31-2.99 1.77-4.15a13 13 0 01-2.09-7.71c.07-2.82 1.95-3.84 3.2-3.86zm-6.65 23.13a12.18 12.18 0 00-3.67 2.65c-.92.94-.94 1.68-.83 1.79.11.11.04.05.11.2.18.36.47.27.75.15h-.01c.17-.07 1.3-.65 3.65-4.79zm15.94-3.11c1.84 1.77 2.52 2.04 3.32 2.26 1.33.36 1.63-.21 1.63-.21a.81.81 0 00-.45-1 9.75 9.75 0 00-4.5-1.05zm-8.77-5.95c-.35.82-.74 1.71-1.19 2.71a268.26 268.26 0 00-.93 2c.61-.15 1.26-.29 1.94-.42 1.22-.24 2.38-.42 3.38-.56l-.18-.19c-1.22-1.34-2.21-2.5-3.02-3.54zm-.68-11.53c-.24.07-.63.38-.63 1.38a9.92 9.92 0 00.75 4.41 10.64 10.64 0 00.66-3.79c-.06-1.32-.48-1.87-.78-2zM14.9 4.977L4.55 15.327H14.9V4.977z'
	]
};