
export default (axios) => ({
    send(data, applicationType = null) {
        const endpoint = applicationType === 'initiativ' ? 'form/speculative-application' : 'form/application'
        const url = `${endpoint}`;
        return axios({
            method: "POST",
            url,
            data,
            // headers
        });
    }
});
