export default {
	prefix: 'isd',
	iconName: 'akademie',
	niceName: 'akademie',
	icon: [
		75,
		57,
		[],
		'ff00',
		'M24.77 14.388a1.25 1.25 0 110 2.5h-4.93v37.32h9.9v-6.58h-2.31a1.25 1.25 0 010-2.5h20.19a1.25 1.25 0 110 2.5h-2.29v6.58h9.9v-37.32h-5.22a1.25 1.25 0 010-2.5h6.47c.69 0 1.25.56 1.25 1.25v38.57h3.31v-4.63a1.25 1.25 0 112.5 0v4.63h3.54v-29.28H60.7a1.25 1.25 0 010-2.5h7.67c.69 0 1.25.56 1.25 1.25v30.53h4a1.25 1.25 0 110 2.5h-72a1.25 1.25 0 010-2.5H5.5v-30.53c0-.69.56-1.25 1.25-1.25h7.63a1.25 1.25 0 110 2.5H8v29.28h3.53v-4.63a1.25 1.25 0 112.5 0v4.63h3.34v-38.57c0-.69.56-1.25 1.25-1.25zm18.06 33.24H32.24v6.58h4.09v-4.36a1.25 1.25 0 112.5 0v4.36h4v-6.58zm-30.05-9.15c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32-.8a1.25 1.25 0 110 2.5H24.1a1.25 1.25 0 010-2.5zm-38.19-9.31c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32 2.09a1.25 1.25 0 110 2.5H24.1a1.25 1.25 0 010-2.5zm0-7.23a1.25 1.25 0 110 2.5H24.1a1.25 1.25 0 010-2.5zM37.27.308a1.25 1.25 0 01.69 0l14.59 4.17c.531.158.897.645.9 1.2v4.26a1.25 1.25 0 01-2.5 0v-2.49l-4.19 1.37v6c0 .343-.141.672-.39.91a12.79 12.79 0 01-8.75 3.19 12.79 12.79 0 01-8.78-3.19 1.26 1.26 0 01-.39-.91v-3.67a1.25 1.25 0 112.5 0v3.16a11.29 11.29 0 0013.28 0v-4.63l-6.25 2a1.3 1.3 0 01-.78 0l-14.58-4.78a1.26 1.26 0 01-.83-1.21 1.24 1.24 0 01.9-1.18zm.35 2.51l-10.33 2.97 10.33 3.38 10.39-3.35-10.39-3z'
	]
};