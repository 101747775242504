export default (axios) => ({
    send(data, token) {
        const url = `form/contact`;

        return axios({
            method: "POST",
            url,
            data,
            // headers
        });
    },
});
