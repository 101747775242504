export default {
	prefix: 'isd',
	iconName: 'download-bild',
	niceName: 'download-bild',
	icon: [
		43,
		56,
		[],
		'ff10',
		'M41.73.435c.69 0 1.25.56 1.25 1.25v53c0 .69-.56 1.25-1.25 1.25H1.51c-.69 0-1.25-.56-1.25-1.25v-38.38a1.15 1.15 0 01.1-.48 1.09 1.09 0 01.27-.4L15.26.805c.122-.11.26-.201.41-.27a1.29 1.29 0 01.48-.1zm-26.22 25.36L2.78 41.355v12.08l37.7-.03v-7.22l-10.95-13.41-2.54 3 5.26 6.43a1.25 1.25 0 01-.17 1.76 1.29 1.29 0 01-.8.29 1.23 1.23 0 01-1-.46l-14.77-18zm24.97-22.86H17.4v13.37c0 .69-.56 1.25-1.25 1.25H2.78v19.86l11.7-14.38a1.23 1.23 0 011-.46c.387-.01.756.16 1 .46l8.8 10.82 3.2-3.84a1.15 1.15 0 011-.45 1.23 1.23 0 011 .46l10 12.24V2.935zm-8.85 12.5a5.75 5.75 0 010 11.5 5.76 5.76 0 01-5.75-5.75 5.75 5.75 0 015.75-5.75zm0 2.5a3.26 3.26 0 00-3.25 3.25 3.25 3.25 0 103.25-3.25zM14.9 4.705L4.55 15.055H14.9V4.705z'
	]
};