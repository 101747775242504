export default {
	prefix: 'isd',
	iconName: 'unternehmensgeschichte',
	niceName: 'unternehmensgeschichte',
	icon: [
		65,
		66,
		[],
		'ff4d',
		'M63.656 20.446c.69 0 1.25.56 1.25 1.25v39.87c0 .69-.56 1.25-1.25 1.25h-24.39c-.61 1.17-1.86 3-3.61 3h-5.94c-1.78-.05-3.04-1.87-3.65-3H1.509a1.25 1.25 0 01-1.173-1.25v-39.87a1.25 1.25 0 012.5 0v38.62h24c.5.002.952.3 1.15.76.49 1.2 1.34 2.2 1.67 2.2h5.88c.38-.01 1.25-1.08 1.72-2.2a1.26 1.26 0 011.15-.76h24v-38.62c0-.69.56-1.25 1.25-1.25zM47.641.129l.115.007.23.11c.062.036.122.076.18.12l.16.18a.69.69 0 01.13.2c.036.074.066.15.09.23.05.05.05.12.05.15v40.95a1.25 1.25 0 01-1.06 1.24c-.32.08-6.85 1.23-11.4 11.08a30.63 30.63 0 0115.59-7v-40.2a1.25 1.25 0 012.5 0v41.33a1.24 1.24 0 01-1.18 1.25 28.44 28.44 0 00-14.47 5.88 49.53 49.53 0 016.94-.27h11.66v-39.63a1.25 1.25 0 012.5 0v40.96c0 .69-.56 1.25-1.25 1.25H44.633c-5.679.005-8.44.13-10.927 3a1.27 1.27 0 01-.55.5h-.17a1.16 1.16 0 01-.37.07l-.125-.01a1.51 1.51 0 01-.245-.05h-.07a1.1 1.1 0 01-.4-.18 1.2 1.2 0 01-.39-.48c-2.513-2.771-5.227-2.895-10.83-2.9h-13.8a1.25 1.25 0 01-1.16-1.28v-40.82c0-.69.56-1.25 1.25-1.25h13c5.76-.02 9.33-.01 12.33 2.57 4.87-15.33 14.58-16.93 15-17h.35a1 1 0 01.23 0zM20.604 16.966H8.006v38.43h12.77c4.75 0 7.93.1 10.63 2v-37.57c-2.484-2.733-5.215-2.855-10.802-2.86zm25.492-14c-2.78 1.08-8.91 4.77-12.19 16.85v33.48c4.11-8.82 9.83-11.44 12.19-12.16z'
	]
};