export default {
	prefix: 'isd',
	iconName: 'management',
	niceName: 'management',
	icon: [
		66,
		55,
		[],
		'ff33',
		'M46.66 25.925c.95.39 1.95.59 2.96.59 3.15 0 5.97-1.88 7.17-4.8a7.74 7.74 0 000-5.94 7.754 7.754 0 00-7.16-4.79c-3.15 0-5.97 1.88-7.17 4.79a7.74 7.74 0 000 5.94c.79 1.91 2.29 3.4 4.2 4.2v.01zm2.98-1.86a5.31 5.31 0 01-5.3-5.3c0-2.92 2.38-5.3 5.3-5.3s5.3 2.38 5.3 5.3-2.38 5.3-5.3 5.3zm6.12 6.94a2.48 2.48 0 00-1.97.81l-3.26 3.61c-.49.54-1.34.56-1.85.03l-3.63-3.74c-.5-.52-1.2-.79-1.92-.76-.59.03-1.16.1-1.71.21-.6.12-1.02.64-1.02 1.25 0 .79.72 1.4 1.5 1.24.39-.08.79-.13 1.19-.16h.19l5.51 5.62c.23.24.55.37.88.37.34 0 .67-.16.9-.4l5.06-5.64h.21c3.11.23 6.74 2.01 6.74 6.32v6.78a1.23 1.23 0 102.46 0v-6.74c0-5-3.77-8.54-9.25-8.81l-.03.01zM36.88 47.495c-.7 0-1.27-.58-1.27-1.3v-8.74c0-5.75-4.71-8.13-8.74-8.41l-16.07.05c-3.99.27-8.26 2.62-8.26 8.41v8.69c0 .72-.57 1.3-1.27 1.3-.7 0-1.27-.58-1.27-1.3v-8.74c0-6.64 4.7-11.09 11.69-11.09.34 0 13.87.05 14.23.05 7.19 0 12.21 4.56 12.21 11.09v8.69c0 .72-.57 1.3-1.27 1.3h.02zm-17.81-27.41a9.67 9.67 0 01-3.71-.74 9.624 9.624 0 01-5.26-5.26c-.99-2.4-1-5.04 0-7.44 1.51-3.65 5.03-6 8.98-6 1.27 0 2.52.25 3.71.74 2.4.99 4.27 2.86 5.26 5.26.99 2.4 1 5.04 0 7.43-1.51 3.65-5.03 6-8.98 6v.01zm.02-16.82c-3.93 0-7.12 3.19-7.12 7.12s3.19 7.12 7.12 7.12 7.12-3.19 7.12-7.12-3.19-7.12-7.12-7.12z M19.09 54.365h-.01c-.41 0-.78-.2-1.01-.54l-5.92-8.54a1.24 1.24 0 01-.11-1.22l5.92-13.26a1.244 1.244 0 012.28.01l5.97 13.53c.18.41.13.88-.13 1.24l-5.97 8.26c-.24.33-.61.52-1.01.52h-.01zm-4.49-9.92l4.52 6.51 4.52-6.25-4.55-10.3-4.49 10.04z'
	]
};