export default {
	prefix: 'isd',
	iconName: 'das-unternehmen',
	niceName: 'das-unternehmen',
	icon: [
		66,
		69,
		[],
		'ff0e',
		'M32.576.645c.69 0 1.25.56 1.25 1.25v6.28h8.35a4.25 4.25 0 014.25 4.25v54.77a1.25 1.25 0 01-2.5 0v-54.77a1.75 1.75 0 00-1.75-1.75h-19.2a1.76 1.76 0 00-1.75 1.75v54.77a1.25 1.25 0 01-2.5 0v-54.77a4.26 4.26 0 014.25-4.25h8.35v-6.28c0-.69.56-1.25 1.25-1.25zm-17.85 31.17a1.25 1.25 0 010 2.5H4.356a1.75 1.75 0 00-1.75 1.75v31.13a1.25 1.25 0 01-2.5 0v-31.13a4.26 4.26 0 014.25-4.25zm46.19 8.09a4.25 4.25 0 014.22 4.25v23a1.25 1.25 0 01-2.5 0v-23a1.75 1.75 0 00-1.72-1.75h-10.57a1.25 1.25 0 010-2.5zm-21.2 24.23a1.25 1.25 0 110 2.5h-14.22a1.25 1.25 0 010-2.5zm-24.99 0a1.25 1.25 0 010 2.5h-7.88a1.25 1.25 0 010-2.5zm43.45 0a1.25 1.25 0 010 2.5h-7.83a1.25 1.25 0 010-2.5zm-18.46-8a1.25 1.25 0 010 2.5h-14.22a1.25 1.25 0 010-2.5zm-24.99 0a1.25 1.25 0 010 2.5h-7.88a1.25 1.25 0 010-2.5zm43.45 0a1.25 1.25 0 010 2.5h-7.83a1.25 1.25 0 010-2.5zm-18.46-8a1.25 1.25 0 010 2.5h-14.22a1.25 1.25 0 010-2.5zm-24.99 0a1.25 1.25 0 010 2.5h-7.88a1.25 1.25 0 010-2.5zm43.45 0a1.25 1.25 0 010 2.5h-7.83a1.25 1.25 0 010-2.5zm-18.46-8a1.25 1.25 0 010 2.5h-14.22a1.25 1.25 0 010-2.5zm-24.99 0a1.25 1.25 0 010 2.5h-7.88a1.25 1.25 0 010-2.5zm24.99-8a1.25 1.25 0 010 2.5h-14.22a1.25 1.25 0 010-2.5zm0-8a1.25 1.25 0 010 2.5h-14.22a1.25 1.25 0 010-2.5zm0-8a1.25 1.25 0 010 2.5h-14.22a1.25 1.25 0 010-2.5z'
	]
};