export default {
	prefix: 'isd',
	iconName: 'akkordeon-plus',
	niceName: 'akkordeon-plus',
	icon: [
		58,
		57,
		[],
		'ff02',
		'M28.62.039c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41-15.69 0-28.41-12.72-28.41-28.41S12.93.039 28.62.039zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91c-.017-14.303-11.607-25.893-25.91-25.91zm0 9.85c.69 0 1.25.56 1.25 1.25l-.001 13.559 13.561.001a1.25 1.25 0 010 2.5l-13.561-.001.001 13.561a1.25 1.25 0 01-2.5 0l-.001-13.561-13.559.001a1.25 1.25 0 010-2.5l13.559-.001.001-13.559c0-.69.56-1.25 1.25-1.25z'
	]
};