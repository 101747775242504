export default {
	prefix: 'isd',
	iconName: 'einzel-grosshandel',
	niceName: 'einzel-grosshandel',
	icon: [
		71,
		54,
		[],
		'ff15',
		'M64.132 22.157c.69 0 1.25.56 1.25 1.25v28.81c0 .69-.56 1.25-1.25 1.25h-57c-.69 0-1.25-.56-1.25-1.25v-28.81a1.25 1.25 0 012.5 0v27.53h15.45v-27.53a1.25 1.25 0 012.5 0v27.53h36.55v-27.53c0-.69.56-1.25 1.25-1.25zm-9.82 13.78a1.25 1.25 0 111.76 1.77l-4.84 4.82a1.24 1.24 0 01-.88.36 1.25 1.25 0 01-.88-2.14zm-.81-6.88a1.25 1.25 0 011.77 1.76l-11.64 11.67a1.259 1.259 0 01-1.79-1.77zm-34.57 2.42c.69 0 1.25.56 1.25 1.25v3.88a1.25 1.25 0 11-2.5 0v-3.88c0-.69.56-1.25 1.25-1.25zM63.472.447a1.25 1.25 0 011.16.81l5.06 10.68a5.92 5.92 0 01-.18 5.65 5.89 5.89 0 01-5.1 2.42h-.48a10.63 10.63 0 01-7.72-3.73 5.69 5.69 0 01-.76 1.28 6.54 6.54 0 01-5.34 2.45h-.48a8.92 8.92 0 01-7.21-3.86 8 8 0 01-1 1.44 7.56 7.56 0 01-5.63 2.42h-.35a7.5 7.5 0 01-5.61-2.42 7 7 0 01-1-1.43 8.94 8.94 0 01-7.2 3.85h-.5a6.55 6.55 0 01-5.34-2.45 5.69 5.69 0 01-.76-1.28 10.63 10.63 0 01-7.72 3.73h-.48a5.89 5.89 0 01-5.1-2.42 5.9 5.9 0 01-.18-5.65l5.1-10.64.06-.07.052-.115A1.23 1.23 0 017.862.447zm-.84 2.49h-8.46l2.39 8.74c.07.05.07.09.07.15a8.23 8.23 0 007.43 5.73h.48a3.44 3.44 0 003-1.29l-.1-.05a3.44 3.44 0 00-.02-3.28l-4.79-10zm-11 0h-9l.75 8.79a.38.38 0 000 .1 6.4 6.4 0 006.25 5.73h.55a4.11 4.11 0 003.36-1.48l-.05-.05a4.08 4.08 0 00.62-3.59l-2.48-9.5zm-11.47 0h-9l-.72 9.11a5 5 0 005.05 5.51h.35a5.1 5.1 0 003.79-1.62 5.06 5.06 0 001.25-3.92l-.72-9.08zm-23.07 0h-8.46l-4.8 10.04a3.42 3.42 0 000 3.24 3.44 3.44 0 003 1.29h.48a8.22 8.22 0 007.43-5.73c.02-.06.07-.14.07-.14l2.28-8.7zm11.54 0h-9l-2.44 9.47a3.84 3.84 0 003.94 5.1h.5a6.4 6.4 0 006.21-5.7c.05-.06.05-.16.05-.16l.74-8.71z'
	]
};