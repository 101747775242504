export default {
	prefix: 'isd',
	iconName: 'scrollen',
	niceName: 'scrollen',
	icon: [
		39,
		70,
		[],
		'ff43',
		'M25.336 61.115a1.252 1.252 0 011.77 1.77l-6.61 6.6a1.23 1.23 0 01-1.76 0l-6.61-6.6a1.252 1.252 0 011.77-1.77l5.72 5.72zM19.746.925c10.494.028 18.989 8.536 19 19.03v19.3c-.033 10.48-8.52 18.967-19 19h-.25c-10.493 0-19-8.507-19-19v-19.33c.033-10.48 8.52-18.967 19-19zm16.52 23.24h-33.3v15.09c.011 9.125 7.405 16.519 16.53 16.53h.25c9.12-.017 16.509-7.41 16.52-16.53v-15.09zm-16.53-20.74h-.25c-9.123.011-16.514 7.407-16.52 16.53v1.71h15.4V8.945c0-.69.56-1.25 1.25-1.25l.128.006a1.25 1.25 0 011.122 1.244v12.72h15.4v-1.71c-.011-9.125-7.405-16.519-16.53-16.53z'
	]
};