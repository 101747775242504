export default {
	prefix: 'isd',
	iconName: 'arbeiten-in-den-nl',
	niceName: 'arbeiten-in-den-nl',
	icon: [
		75,
		52,
		[],
		'ff03',
		'M23.77 4.115a1.25 1.25 0 010 2.5h-3.93v42.32h9.9v-6.58h-2.31a1.25 1.25 0 110-2.5h20.19a1.25 1.25 0 010 2.5h-2.29v6.58h9.9V6.615H51.3a1.25 1.25 0 110-2.5h5.18c.69 0 1.25.56 1.25 1.25v43.57h3.31v-4.63a1.25 1.25 0 012.5 0v4.63h3.54v-29.28H60.7a1.25 1.25 0 110-2.5h7.67c.69 0 1.25.56 1.25 1.25v30.53h4a1.25 1.25 0 010 2.5h-72a1.25 1.25 0 110-2.5H5.5v-30.53c0-.69.56-1.25 1.25-1.25h7.63a1.25 1.25 0 010 2.5H8v29.28h3.53v-4.63a1.25 1.25 0 012.5 0v4.63h3.34V5.365c0-.69.56-1.25 1.25-1.25zm19.06 38.24H32.24v6.58h4.09v-4.36a1.25 1.25 0 012.5 0v4.36h4v-6.58zm-30.05-9.15c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32-1.61a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zm-38.19-8.5c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 11-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32 1.28a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zm0-7.22a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 110-2.5zM42.71.785c3.33 0 5.16 1.83 5.16 4.58 0 3.07-2.08 4.81-5.12 4.86a22 22 0 01-3.13-.19v-9a17.8 17.8 0 013.09-.25zM35 .725a5.33 5.33 0 013.25.94 2.57 2.57 0 01-1.14 2.05 4.06 4.06 0 00-2.31-.68c-.65 0-1 .13-1 .45s.19.44 1.81.83c1.62.39 2.8 1.12 2.8 2.69.06 2.07-1.35 3.18-3.6 3.18a5.9 5.9 0 01-3.74-1.08 2.57 2.57 0 011.09-2 4.92 4.92 0 002.71.8c.82 0 1.05-.12 1.05-.47s-.12-.48-1.69-.88c-1.57-.4-2.91-.95-2.92-2.83-.01-1.88 1.47-3 3.69-3zm-6.27.21c.432 0 .863.033 1.29.1v9a7 7 0 01-1.23.1 8.28 8.28 0 01-1.29-.1v-9a7 7 0 011.23-.1zm14.302 2.224l-.182.006h-.75v4.65h.75a2.15 2.15 0 002.24-2.4 2.09 2.09 0 00-2.24-2.25z'
	]
};