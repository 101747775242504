export default {
	prefix: 'isd',
	iconName: 'kontaktformular',
	niceName: 'kontaktformular',
	icon: [
		77,
		50,
		[],
		'ff2e',
		'M63.406.598a5.58 5.58 0 015.57 5.57v.58a1.25 1.25 0 11-2.5 0v-.58a3.08 3.08 0 00-3.07-3h-50.49a3.07 3.07 0 00-3.06 3v35.33h21.82c.363.002.708.158.95.43l.66.81h9.73l.67-.77a1.24 1.24 0 01.94-.43h21.85v-5.37a1.25 1.25 0 012.5 0v5.33h5.65a1.25 1.25 0 011.25 1.49c-.07.27-1.54 6.69-6.66 6.69h-62.1c-5.12 0-6.59-6.42-6.66-6.69a1.25 1.25 0 011.23-1.52h5.67v-35.3a5.58 5.58 0 015.56-5.57zm-32.29 43.36h-27.7c.6 1.38 1.76 3.21 3.7 3.21h62.1c1.96 0 3.11-1.79 3.71-3.21h-27.7l-.66.76a1.24 1.24 0 01-.94.44h-10.91a1.22 1.22 0 01-.94-.44l-.66-.76zm-5.61-36.04c.69 0 1.25.56 1.25 1.25v.82h6.7a1.25 1.25 0 110 2.5h-6.71v13.86h1.8a1.27 1.27 0 011.08.62l4.75 8.09a2 2 0 002.25 1l1.31-.46-1.57-2.72a1.252 1.252 0 012.17-1.25l1.82 3.18.063.135c.141.261.364.47.637.595a.7.7 0 00.48-.08l.09-.06 1.61-.87-2.79-5a1.25 1.25 0 012.18-1.21l2.75 5.05.093.15c.587.891 1.076.804 1.827.5l1.44-1a1.74 1.74 0 01-.11-.18l-3.93-6.18a1.25 1.25 0 012.11-1.34l4 6.22c.68 1.19 1.15 1.44 1.28 1.44l.062-.012c.307-.09.79-.68 1.238-1.538l-.15-.28a1.32 1.32 0 01-.37-.71l-6.6-12.57-2.64-.23-4.06 4.12-.13.12a4.91 4.91 0 01-4.22 1.13 5.63 5.63 0 01-3.44-2.93 1.24 1.24 0 01.13-1.31l5.75-7.51c2.24-2.83 4.58-2.94 4.84-2.94h8.67a1.24 1.24 0 01.5.1l6.83 3v-.37c0-.69.56-1.25 1.25-1.25h15.77a1.25 1.25 0 110 2.5h-14.52v18.3h14.57v.02a1.25 1.25 0 110 2.5h-15.86c-.69 0-1.25-.56-1.25-1.25v-.37h-2.38c-.64 1.62-1.87 3.83-3.81 4h-.24a2.87 2.87 0 01-1.66-.57l-1.74 1.24a1.49 1.49 0 01-.17.13 5.23 5.23 0 01-2 .44 3.29 3.29 0 01-1.43-.32l-2.18 1.17a3.44 3.44 0 01-1.68.47 2.79 2.79 0 01-.68-.08 3 3 0 01-.86-.36l-2.29.88h-.17c-.35.07-.704.108-1.06.11a4.46 4.46 0 01-3.93-2.32l-4.34-7.44h-1.08v1.12c0 .69-.56 1.25-1.25 1.25h-6.75a1.25 1.25 0 010-2.5h5.5v-18.3h-5.45a1.25 1.25 0 010-2.5zm25.4 2.89h-8.4a4.55 4.55 0 00-2.88 2l-5.13 6.78a2.72 2.72 0 001.38 1 2.6 2.6 0 002-.65l4.41-4.48a1.27 1.27 0 011-.37l3.88.34c.423.036.8.284 1 .66l6.76 12.89h3.57v-14.81l-7.59-3.36z'
	]
};