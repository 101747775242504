export default {
	prefix: 'isd',
	iconName: 'dropdown-unten',
	niceName: 'dropdown-unten',
	icon: [
		58,
		58,
		[],
		'ff13',
		'M28.62.499c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41-15.69 0-28.41-12.72-28.41-28.41S12.93.499 28.62.499zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91c-.017-14.303-11.607-25.893-25.91-25.91zm0 10.33c.69 0 1.25.56 1.25 1.25v25.639l10.347-10.347a1.25 1.25 0 011.77 1.76L29.585 44.034c-.14.17-.324.301-.534.379l-.032.01a1.052 1.052 0 01-.207.051c-.02.004-.04.006-.061.008l-.03.003a1.123 1.123 0 01-.101.004l-.101-.006h-.027-.003l.131.006a1.22 1.22 0 01-.4-.065l-.031-.012a1.247 1.247 0 01-.537-.382L15.35 31.73a1.25 1.25 0 111.77-1.76l10.25 10.249V14.579c0-.69.56-1.25 1.25-1.25z'
	]
};