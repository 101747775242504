export default {
	prefix: 'isd',
	iconName: 'facebook',
	niceName: 'facebook',
	icon: [
		64,
		64,
		[],
		'ff16',
		'M60.5-.1c2 0 3.5 1.6 3.5 3.5v57c0 2-1.6 3.5-3.5 3.5H44.1V39.2h8.3l1.3-9.7h-9.6v-6.2c0-2.8.8-4.7 4.8-4.7H54V10c-.9-.1-3.9-.4-7.4-.4-7.4 0-12.5 4.5-12.5 12.8v7.1h-8.4v9.7h8.4V64H3.4c-2 0-3.5-1.6-3.5-3.5v-57c0-2 1.6-3.5 3.5-3.5l57.1-.1z'
	]
};