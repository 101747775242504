export default {
	prefix: 'isd',
	iconName: 'grundstuecks-wohnungswaltung',
	niceName: 'grundstuecks-wohnungswaltung',
	icon: [
		70,
		61,
		[],
		'ff1e',
		'M53.984 15.198a7.81 7.81 0 017.79 7.79v10.45c0 4.36-1.77 7.08-4.77 7.66v17h3.26a1.25 1.25 0 010 2.5h-59a1.25 1.25 0 010-2.5h6.35v-18.52a1.25 1.25 0 01.42-.93l17.26-15.46a1.3 1.3 0 011.66 0l7 6a1.25 1.25 0 11-1.64 1.89l-6.14-5.32-16.06 14.34v18h10.33v-10c0-.69.56-1.25 1.25-1.25h8.94a1.25 1.25 0 011.25 1.22v10h9.64v-17c-3.05-.58-4.77-3.3-4.77-7.66v-10.42a7.8 7.8 0 017.77-7.79zm14.02 42.9a1.25 1.25 0 010 2.5h-3.43a1.25 1.25 0 110-2.5zm-14.04-40.4h-9.46a5.28 5.28 0 00-5.27 5.29v10.42c0 3.54 1.2 4.71 2.27 5.09v-13.54a1.25 1.25 0 112.5 0v14.7a.84.84 0 010 .57v17.87h4v-17.87a1.25 1.25 0 112.5 0v17.87h4v-17.86a1 1 0 01-.06-.29.88.88 0 01.06-.29v-14.7a1.25 1.25 0 112.5 0v13.54c1.06-.39 2.27-1.55 2.27-5.09l-.02-10.42a5.29 5.29 0 00-5.29-5.29zm-24.58 31.62h-6.44v8.75h6.44v-8.75zm-4.08-33.71a1.26 1.26 0 011.63 0l7 5.9a1.25 1.25 0 11-1.6 1.91l-6.23-5.21-10.86 9.48a1.25 1.25 0 01-2.07-.94v-5.26h-2.91v10a1.26 1.26 0 01-.43.94l-7.77 6.8c-.227.2-.518.31-.82.31a1.23 1.23 0 01-.94-.45 1.25 1.25 0 01.11-1.77l7.35-6.4v-10.6c0-.69.56-1.25 1.25-1.25h5.37c.69 0 1.25.56 1.25 1.25v3.71zM49.264.628a6.17 6.17 0 110 12.34 6.17 6.17 0 010-12.34zm0 2.5a3.67 3.67 0 10.08 7.34 3.67 3.67 0 00-.08-7.34z'
	]
};