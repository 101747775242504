export default {
	prefix: 'isd',
	iconName: 'kununu',
	niceName: 'kununu',
	icon: [
		64,
		64,
		[],
		'ff39',
		'M60.2 0H3.8C1.7 0 0 1.7 0 3.8v56.3C0 62.3 1.7 64 3.8 64h56.3c2.1 0 3.8-1.7 3.8-3.8V3.8C64 1.7 62.3 0 60.2 0zM44.8 7.2c.2-.4.6-.8.9-1.1.4-.3.8-.6 1.3-.6.8-.1 1.7.1 2.3.7l2 2c.2.3.2.7 0 .9-.2.2-.5.5-.8.5s-.5-.3-.7-.5l-1.6-1.7c-.3-.3-.8-.3-1.1 0-.2.2-.4.4-.5.6-.2.3-.1.7.1.9.6.7 1.3 1.3 2 2 .2.2.2.6 0 .9-.1.1-.2.3-.4.4-.3.2-.6.2-.9 0l-2-2.1c-.8-.7-1-1.9-.6-2.9zm-3.1 49.5c0 .7-.6 1.3-1.3 1.3h-7.8c-.4 0-.7 0-1-.2-.4-.3-.6-.8-.6-1.3V46c.1-2.2-1.2-4.3-3.3-5.1-1.5-.4-3-.6-4.5-.4-1.7-.1-3.4.6-4.5 2-.8 1.1-1.3 2.5-1.3 3.8v10.4c0 .7-.6 1.3-1.3 1.3H7.8c-.6 0-1-.4-1.2-.9V7.8c0-.8.5-1.4 1.3-1.5h8.2c.6 0 1.2.4 1.4 1V30.7c1.8-.6 3.7-.9 5.6-.9 1.3.1 2.6 0 3.8-.2 1.9-.4 3.3-1.9 3.8-3.7.1-.9.2-1.7.3-2.6 0-.6.1-1.2.2-1.7.2-.5.7-.7 1.2-.7h8.1c.7 0 1.2.6 1.3 1.3.1 2 0 3.9-.4 5.9-.6 2.6-1.8 5-3.6 6.9-.2.1 0 .3.1.4 2.6 2.9 4 6.7 3.9 10.6.1 3.5.1 7.1-.1 10.7zm9.6-39.3L49.7 19c-.3.3-.6.6-.9.8-.7.4-1.7.5-2.4.1-.4-.3-.8-.6-1.2-1-.8-.9-.8-2.3 0-3.3.6-.7 1.3-1.3 2-2 .1-.1.2-.2.4-.3.3-.1.7.1.9.4.3.3.3.7 0 1-.6.6-1.3 1.2-1.9 1.8-.2.2-.3.5-.3.8 0 .3.3.6.6.8.3.3.8.3 1.1 0l1.8-1.7c.1-.2.4-.3.6-.4.3 0 .6.2.8.5.3.2.3.6.1.9zm7.3 1c-.3.4-.6.8-1 1.1-1 .8-2.4.8-3.3-.1l-2-2c-.3-.2-.3-.6-.2-.9.1-.2.3-.3.4-.4.3-.2.6-.2.9 0l2 2c.3.3.7.3 1 .1l.6-.6c.2-.3.2-.8-.1-1l-1.7-1.7c-.2-.2-.4-.3-.4-.6s.3-.8.7-1c.3-.1.6 0 .8.2l1.7 1.7c.3.3.6.6.8 1 .2.6.2 1.5-.2 2.2zm-.1-8.7c-.2.3-.4.5-.7.8l-1.6 1.6c-.3.3-.7.3-1 0-.2-.2-.5-.5-.5-.8 0-.2.2-.5.4-.6L57 9c.2-.2.3-.4.2-.7 0-.4-.4-.6-.6-.8-.3-.3-.8-.3-1.1 0-.7.6-1.3 1.3-1.9 1.9-.3.3-.7.2-1 0-.4-.2-.5-.7-.2-1.1l2-2.1c.4-.4.9-.6 1.4-.7.7-.1 1.5.1 2.1.6.3.3.6.6.8.9.4.9.4 1.9-.2 2.7z'
	]
};