export default {
	prefix: 'isd',
	iconName: 'arbeiten-in-den-objekten',
	niceName: 'arbeiten-in-den-objekten',
	icon: [
		63,
		56,
		[],
		'ff04',
		'M55.153 29.029c2.72-1.46 5.89-.71 7.2 1.73a5.35 5.35 0 01-2.47 7.18l-22.35 11.92a1 1 0 01-.29.11l-1.18.3h-.27l-20.66.56a14 14 0 00-5.19 4 1.27 1.27 0 01-.94.43 1.25 1.25 0 01-.83-.32 1.24 1.24 0 01-.11-1.78c3.43-3.89 6.39-4.78 6.51-4.78a1 1 0 01.3 0l20.71-.56.9-.22 22.22-11.91a2.87 2.87 0 001.45-3.75c-.65-1.2-2.33-1.51-3.84-.7l-10.8 5.52a1.25 1.25 0 01-1.14-2.22zm-44.8 4.31c4.885-3.302 10.517-1.757 11.124-1.574l.046.014a44.39 44.39 0 007.25.8h8.14a5.43 5.43 0 015.68 5.12 5.43 5.43 0 01-5.68 5.12h-11.32a1.25 1.25 0 110-2.5h11.32a3 3 0 003.18-2.62 3 3 0 00-3.18-2.62h-8.18a46.07 46.07 0 01-7.9-.9c-.1-.03-4.98-1.48-9.01 1.18l-9.38 8.64a1.26 1.26 0 01-.85.33 1.25 1.25 0 01-.85-2.17l9.46-8.71zM45.993.589a1.25 1.25 0 011.1.65l8.61 15.85c.22.384.22.856 0 1.24a1.23 1.23 0 01-1.07.61h-4.25v9.59a1.25 1.25 0 11-2.5 0v-9.59h-11.84v10.82a1.25 1.25 0 11-2.5 0v-10.85h-3.77a1.25 1.25 0 01-1.09-.64l-4.5-8.16-5.44 8.24-.07.08v9.88a1.25 1.25 0 11-2.5 0v-9.37h-5.22a1.25 1.25 0 01-1.05-1.95l10.09-15.81a.91.91 0 01.11-.16c.04-.046.084-.09.13-.13a1.2 1.2 0 01.78-.3zm-.77 2.5h-22.05l7.35 13.32h22l-7.3-13.32zm-24.25 1.2l-7.73 12.12h3.78l5.8-8.77-1.85-3.35z'
	]
};