export default {
	prefix: 'isd',
	iconName: 'slider-links',
	niceName: 'slider-links',
	icon: [
		58,
		58,
		[],
		'ff44',
		'M28.62.755c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41-15.69 0-28.41-12.72-28.41-28.41S12.93.755 28.62.755zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91a25.753 25.753 0 00-25.91-25.91zm-1.586 12.263a1.25 1.25 0 01.624 2.05L17.309 27.915H42.95a1.25 1.25 0 010 2.5H17.311l10.249 10.25a1.25 1.25 0 010 1.77 1.21 1.21 0 01-.88.37 1.21 1.21 0 01-.88-.37L13.495 30.13a1.253 1.253 0 01-.38-.536l-.009-.03a.929.929 0 01-.027-.09l-.01-.042a1.172 1.172 0 01-.03-.267 1.23 1.23 0 01.066-.4l.012-.03c.077-.212.21-.397.382-.538L25.8 15.895a1.25 1.25 0 011.234-.377z'
	]
};