export default {
	prefix: 'isd',
	iconName: 'industrie-logistik',
	niceName: 'industrie-logistik',
	icon: [
		66,
		69,
		[],
		'ff24',
		'M19.15 19.565a1.24 1.24 0 011.24 1.19l2.47 45.39h35.1v-25.58l-10.79 6.77a1.24 1.24 0 01-1.26 0 1.25 1.25 0 01-.65-1.09v-5.68l-10.73 6.67a1.26 1.26 0 01-1.27 0 1.24 1.24 0 01-.64-1.09v-5.58l-6.89 4.25a1.24 1.24 0 01-1.72-.4 1.26 1.26 0 01.4-1.72l8.81-5.48a1.24 1.24 0 011.26 0c.399.218.648.635.65 1.09v5.58l10.73-6.66a1.24 1.24 0 011.9 1.07v5.67l10.79-6.7a1.26 1.26 0 011.27 0c.396.219.642.637.64 1.09v27.79h3.37a1.25 1.25 0 110 2.5H1.41a1.25 1.25 0 010-2.5h3.43l2.47-45.4a1.24 1.24 0 011.24-1.18zm-1.19 2.51H9.74l-2.38 44.11h13l-2.05-37.61h-6.13a1.25 1.25 0 010-2.5h6l-.22-4zm13.99 30.87a1.25 1.25 0 110 2.5H26.4a1.25 1.25 0 010-2.5zm10.92 0a1.25 1.25 0 110 2.5h-5.55a1.25 1.25 0 010-2.5zm10.92 0a1.25 1.25 0 110 2.5h-5.55a1.25 1.25 0 010-2.5zM30.851.476l.28.003a9.32 9.32 0 014.719 17.206 1.26 1.26 0 01-.67.19 1.25 1.25 0 01-.67-2.31 6.81 6.81 0 10-9.89-8.29 9.14 9.14 0 012.21 1.06 1.25 1.25 0 11-1.36 2.09 6.71 6.71 0 00-10.34 5.63 1.25 1.25 0 01-2.5.01 9.21 9.21 0 019.2-9.2h.21A9.32 9.32 0 0131.13.479z'
	]
};