export default {
	prefix: 'isd',
	iconName: 'linkedin',
	niceName: 'linkedin',
	icon: [
		64,
		64,
		[],
		'ff2f',
		'M58 0H6C2.7 0 0 2.7 0 6v52c0 3.3 2.7 6 6 6h52c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6zM24 52h-8V24h8v28zm-4-32c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm32 32h-8V36c0-2.2-1.8-4-4-4s-4 1.8-4 4v16h-8V24h8v5c1.7-2.3 4.2-5 7-5 5 0 9 4.5 9 10v18z'
	]
};