export default {
	prefix: 'isd',
	iconName: 'xing',
	niceName: 'xing',
	icon: [
		64,
		64,
		[],
		'ff53',
		'M58 0H6C2.7 0 0 2.7 0 6v52c0 3.3 2.7 6 6 6h52c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6zM19.5 41.6h-6.9c-.4 0-.7-.2-.9-.5-.2-.3-.2-.7 0-1.1l7.4-13-4.7-8c-.2-.4-.2-.8 0-1.1.2-.3.5-.4.9-.4h6.9c1.1 0 1.6.7 1.9 1.3 0 0 4.7 8.2 4.8 8.3-.3.5-7.5 13.2-7.5 13.2-.3.6-.8 1.3-1.9 1.3zM52.3 9.5L37 36.6l9.8 17.8c.2.4.2.8 0 1.1-.2.3-.5.4-.9.4H39c-1.1 0-1.6-.7-1.9-1.3l-9.8-18c.5-.9 15.4-27.3 15.4-27.3.2-.6.6-1.3 1.7-1.3h7c.4 0 .8.2.9.4.2.3.2.7 0 1.1z'
	]
};