export default {
	prefix: 'isd',
	iconName: 'kindergaerten',
	niceName: 'kindergaerten',
	icon: [
		64,
		65,
		[],
		'ff2a',
		'M61.83 50.03c.333 0 .651.133.886.368.497.473.52 1.259.05 1.76a39.47 39.47 0 01-28.72 12 39.5 39.5 0 01-28.72-12 1.25 1.25 0 011.82-1.71 37 37 0 0026.9 11.26 37 37 0 0026.9-11.31 1.25 1.25 0 01.885-.368zM33.847 36.938c11.03 0 14.39 8.22 16.7 15.92a1.24 1.24 0 01-.91 1.55 1.13 1.13 0 01-.36.06 1.26 1.26 0 01-1.2-.9c-2.11-7.13-5.01-14.13-14.27-14.13-8.753 0-11.815 6.236-13.906 12.957l-.18.585-.174.588a1.26 1.26 0 01-1.52.84 1.24 1.24 0 01-.84-1.55c1.66-5.56 4.72-15.92 16.66-15.92zM13.236-.002c.16 0 12.09.66 13.16 18.5 0 .37.94 9.82 9 9.82h.15a8.89 8.89 0 009.44-6.5 1.25 1.25 0 011.2-.89h6.02c.77-.05 6.18-.22 10.64 6.1a1.25 1.25 0 11-2 1.44c-2.66-3.79-5.61-4.76-7.21-5l1.65 27.2a1.25 1.25 0 01-1.16 1.33h-.09a1.25 1.25 0 01-1.24-1.17l-1.75-27.38h-4a11.28 11.28 0 01-10.76 7.46 8.22 8.22 0 01-1-.06c-8.24-.04-11.1-7.95-11.46-12.13-.73-12.28-6.74-15.27-9.45-16v3.7a1.26 1.26 0 01-.61 1.07c-.045.028-2.635 1.59-5.32 3.27l-.78.489-.516.326c-1.626 1.03-3.138 2.016-3.974 2.635a1.9 1.9 0 00-.57 2.17 2.39 2.39 0 002.24 1.72h11.39a1.25 1.25 0 110 2.5H4.916a4.91 4.91 0 01-4.62-3.44 4.36 4.36 0 011.47-5c2.18-1.58 8.18-5.29 10.18-6.48v-4.43a1.25 1.25 0 01.38-.91 1.22 1.22 0 01.91-.34zm1.245 23.115c.69.03 1.225.614 1.195 1.305l-1.13 26.34a1.25 1.25 0 01-1.25 1.2h-.05a1.25 1.25 0 01-1.2-1.3l1.13-26.35c.03-.69.614-1.226 1.305-1.195zm26.065-2.235a1.25 1.25 0 110 2.5h-9.16a1.25 1.25 0 010-2.5z'
	]
};