export default {
	prefix: 'isd',
	iconName: 'zahlen-daten-fakten',
	niceName: 'zahlen-daten-fakten',
	icon: [
		63,
		54,
		[],
		'ff54',
		'M1.79.207c.69 0 1.25.56 1.25 1.25v49.49h58.41a1.25 1.25 0 010 2.5H1.79c-.69 0-1.25-.56-1.25-1.25V1.457c0-.69.56-1.25 1.25-1.25zm20.43 22.48c.69 0 1.25.56 1.25 1.25v22.5a1.24 1.24 0 01-1.25 1.25c-.69 0-1.25-.56-1.25-1.25v-22.5c0-.69.56-1.25 1.25-1.25zm6.81-.92c.69 0 1.25.56 1.25 1.25v23.42a1.25 1.25 0 01-2.5 0v-23.42c0-.69.56-1.25 1.25-1.25zm6.84 3.88c.69 0 1.25.56 1.25 1.25v19.54a1.25 1.25 0 11-2.5 0v-19.54c0-.69.56-1.25 1.25-1.25zm6.75-.46c.69 0 1.25.56 1.25 1.25v20a1.24 1.24 0 01-1.25 1.25c-.69 0-1.25-.56-1.25-1.25v-20c0-.69.56-1.25 1.25-1.25zm13.62-7.21c.69 0 1.25.56 1.25 1.25v27.21a1.25 1.25 0 01-2.5 0v-27.21c0-.69.56-1.25 1.25-1.25zM8.62 35.327c.69 0 1.25.56 1.25 1.25v9.86a1.25 1.25 0 01-2.5 0v-9.86c0-.69.56-1.25 1.25-1.25zm6.8-6.37c.69 0 1.25.56 1.25 1.25v16.23a1.25 1.25 0 01-2.5 0v-16.23c0-.69.56-1.25 1.25-1.25zm34.02-9.92c.69 0 1.25.56 1.25 1.25v26.15a1.25 1.25 0 01-2.5 0v-26.15c0-.69.56-1.25 1.25-1.25zm6.94-14.29c.334.003.653.135.89.37.231.234.36.55.36.88v8.66a1.24 1.24 0 01-1.25 1.25c-.69 0-1.25-.56-1.25-1.25V8.941L39.81 23.717a1.24 1.24 0 01-1.52.16l-13.41-8.35-17.37 17.47a1.25 1.25 0 01-.89.37 1.24 1.24 0 01-.88-.36 1.26 1.26 0 010-1.77l18.07-18.18a1.26 1.26 0 011.55-.18l13.42 8.36L53.29 7.246l-5.58.001a1.25 1.25 0 010-2.5h8.67z'
	]
};