export default {
	prefix: 'isd',
	iconName: 'medical-service-center',
	niceName: 'medical-service-center',
	icon: [
		75,
		62,
		[],
		'ff34',
		'M23.77 10.067a1.25 1.25 0 010 2.5h-3.93v46.32h9.9v-6.58h-2.31a1.25 1.25 0 010-2.5h20.19a1.25 1.25 0 010 2.5h-2.29v6.58h9.9v-46.32h-4.22a1.25 1.25 0 010-2.5h5.47c.69 0 1.25.56 1.25 1.25v47.57h3.31v-4.63a1.25 1.25 0 012.5 0v4.63h3.54v-29.28H60.7a1.25 1.25 0 010-2.5h7.67c.69 0 1.25.56 1.25 1.25v30.53h4a1.25 1.25 0 010 2.5h-72a1.25 1.25 0 010-2.5H5.5v-30.53c0-.69.56-1.25 1.25-1.25h7.63a1.25 1.25 0 010 2.5H8v29.28h3.53v-4.63a1.25 1.25 0 012.5 0v4.63h3.34v-47.57c0-.69.56-1.25 1.25-1.25zm19.06 42.24H32.24v6.58h4.09v-4.36a1.25 1.25 0 012.5 0v4.36h4v-6.58zm-30.05-9.15c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32-1.61a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 010-2.5zm-38.19-8.5c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm49.51 0c.69 0 1.25.56 1.25 1.25v5.15a1.25 1.25 0 01-2.5 0v-5.15c0-.69.56-1.25 1.25-1.25zm-11.32 1.28a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 010-2.5zm0-7.22a1.25 1.25 0 010 2.5H24.1a1.25 1.25 0 010-2.5zM40.79.597c.69 0 1.25.56 1.25 1.25v5h5c.69 0 1.25.56 1.25 1.25v6.53c0 .69-.56 1.25-1.25 1.25h-5v5c0 .69-.56 1.25-1.25 1.25h-6.53c-.69 0-1.25-.56-1.25-1.25v-5h-5a1.25 1.25 0 01-1.24-1.25v-6.53c0-.69.56-1.25 1.25-1.25h5v-5c0-.69.56-1.25 1.25-1.25zm-1.28 2.5h-4v5c0 .69-.56 1.25-1.25 1.25h-5v4h5c.69 0 1.25.56 1.25 1.25v5h4v-5c0-.69.56-1.25 1.25-1.25h5v-4h-5c-.69 0-1.25-.56-1.25-1.25v-5z'
	]
};