export default {
	prefix: 'isd',
	iconName: 'teaser-ausklappen',
	niceName: 'teaser-ausklappen',
	icon: [
		59,
		59,
		[],
		'ff48',
		'M29.62.569c15.878 0 28.75 12.872 28.75 28.75-.022 15.87-12.88 28.728-28.75 28.75-15.878 0-28.75-12.872-28.75-28.75S13.742.569 29.62.569zm0 2.5c-14.497 0-26.25 11.753-26.25 26.25s11.753 26.25 26.25 26.25 26.25-11.753 26.25-26.25c-.022-14.488-11.762-26.228-26.25-26.25zM15.5 23.439l14.12 14.12 14.1-14.11a1.252 1.252 0 011.77 1.77l-15 15a1.22 1.22 0 01-.87.36 1.24 1.24 0 01-.89-.37l-15-15a1.252 1.252 0 011.77-1.77z'
	]
};