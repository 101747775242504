export default {
	prefix: 'isd',
	iconName: 'ueber-uns-one',
	niceName: 'ueber-uns-one',
	icon: [
		58,
		58,
		[],
		'ff4a',
		'M28.62.935c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41-15.69 0-28.41-12.72-28.41-28.41S12.93.935 28.62.935zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91 25.91-11.6 25.91-25.91c-.017-14.303-11.607-25.893-25.91-25.91zm-6.06 23.72h8.9a5 5 0 015 5v8.13a4.06 4.06 0 01-.31 8.1l-.04-.04H23a4.06 4.06 0 110-8.12h1.56v-6.82h-2a3.35 3.35 0 01-3.51-2.5 1.32 1.32 0 01-.08-.44v-.31a1.32 1.32 0 01.08-.44 3.4 3.4 0 013.51-2.56zm8.87 2.5h-8.9c-.3 0-1 .07-1.08.65.09.49.63.6 1.08.6h3.28a1.25 1.25 0 011.25 1.25v9.36c0 .69-.56 1.25-1.25 1.25H23a1.56 1.56 0 000 3.12h13.11a1.56 1.56 0 000-3.12h-.94c-.69 0-1.25-.56-1.25-1.25v-9.36a2.49 2.49 0 00-2.49-2.5zm.747-18.828a6.87 6.87 0 014.243 6.348 6.88 6.88 0 01-6.86 6.87 6.87 6.87 0 112.617-13.218zm-2.617 1.978a4.37 4.37 0 10-.02 8.74 4.37 4.37 0 00.02-8.74z'
	]
};