import defu from 'defu'
import api from '~typo3/plugins/api'
import i18n from '~typo3/plugins/i18n'
import domains from '~typo3/plugins/domains'
export default function (context, inject) {
  const runtimeConfig = context.$config && context.$config.typo3
  let moduleOptions = {"baseURL":undefined,"api":{"baseURL":undefined,"endpoints":{"initialData":"\u002F?type=834"}},"layouts":{},"i18n":{"locales":["en"],"defaultLocale":"en","onLocaleChange":() => null,"beforeLocaleChange":() => null},"registerComponents":true,"registerLayouts":true,"debug":false}

  if (runtimeConfig) {
    moduleOptions = defu(runtimeConfig, moduleOptions)
  }

  const _options = {
    api: api(context, moduleOptions),
    i18n: i18n(context, moduleOptions),
    domains: domains(context, moduleOptions),
    options: moduleOptions
  }
  inject('typo3', _options)
}
