import lazysizes from 'lazysizes'

const lsConfig = {}

Object.assign(lazysizes.cfg, lsConfig)

  require('lazysizes/plugins/parent-fit/ls.parent-fit')

  if(process.client){
      require('lazysizes/plugins/native-loading/ls.native-loading')
  }
