export default {
	prefix: 'isd',
	iconName: 'slider-rechts',
	niceName: 'slider-rechts',
	icon: [
		58,
		58,
		[],
		'ff45',
		'M28.62.8c15.69 0 28.41 12.72 28.41 28.41S44.31 57.62 28.62 57.62C13.093 57.604.481 45.146.214 29.68l-.004-.47C.21 13.52 12.93.8 28.62.8zm0 2.51c-14.299.017-25.888 11.601-25.91 25.9a25.91 25.91 0 1025.91-25.9zm2.82 12.63l12.319 12.317a1.247 1.247 0 01.323 1.484l-.016.032a1.002 1.002 0 01-.073.127l-.026.037c-.04.058-.086.112-.137.163L31.44 42.48a1.21 1.21 0 01-.88.37 1.21 1.21 0 01-.88-.37 1.25 1.25 0 010-1.77l10.25-10.251-25.64.001a1.25 1.25 0 010-2.5l25.64-.001L29.68 17.71a1.25 1.25 0 111.76-1.77z'
	]
};