export default {
	prefix: 'isd',
	iconName: 'verbandstagungen',
	niceName: 'verbandstagungen',
	icon: [
		65,
		63,
		[],
		'ff4e',
		'M38.971 31.309a1.25 1.25 0 110 2.5H3.381v2.86l10 5.56c.389.217.632.624.64 1.07l.39 17h28.34a1.25 1.25 0 110 2.5v-.05h-29.6a1.25 1.25 0 01-1.25-1.19l-.4-17.49-10-5.58a1.25 1.25 0 01-.64-1.09v-4.84c0-.69.56-1.25 1.25-1.25h36.86zm18.83-12.61a5.21 5.21 0 015.08 4.6l1.48 14.7c.24 2.34-1.75 4.1-3.8 5.05l-.45 18.51a1.23 1.23 0 01-1.28 1.22 1.25 1.25 0 01-1.2-1.31l.47-19.33a1.24 1.24 0 01.83-1.14c1.51-.55 3.07-1.71 3-2.78l-1.54-14.66a2.7 2.7 0 00-2.59-2.35h-8.7a2.69 2.69 0 00-2.59 2.35l-1.49 14.7c-.1 1.07 1.45 2.23 3 2.78.487.17.817.624.83 1.14l.47 19.33a1.26 1.26 0 01-1.22 1.28l-.06-.04a1.24 1.24 0 01-1.25-1.19l-.45-18.51c-2.05-1-4-2.71-3.8-5.05l1.48-14.7a5.21 5.21 0 015.08-4.6h8.7zm-33.97-2.14c.339.001.663.138.9.38l3.51 3.62 3.22-3.56a1.26 1.26 0 01.93-.41c4.61 0 7.83 2.87 7.83 7v5.1a1.25 1.25 0 01-2.5 0v-5.13c0-2.88-2.33-4.28-4.8-4.45l-3.72 4.15a1.29 1.29 0 01-.91.41 1.23 1.23 0 01-.91-.38l-4.06-4.15c-2.26.15-4.51 1.39-4.51 4.45v5.1a1.25 1.25 0 01-2.5 0v-5.13c0-4.17 3-7 7.52-7zm31.976-12.13a6.19 6.19 0 11-4.732 11.44 6.19 6.19 0 014.732-11.44zm-24.38-3.37a6.18 6.18 0 013.014 5.56v1.86a6.22 6.22 0 01-6.17 6.25 6.22 6.22 0 01-6.18-6.25v-1.86a6.18 6.18 0 019.336-5.56zm22.024 5.41a3.69 3.69 0 100 7.38 3.69 3.69 0 000-7.38zm-25.18-3.6a3.72 3.72 0 00-3.64 3.75v1.86a3.68 3.68 0 107.35 0v-1.86a3.72 3.72 0 00-3.71-3.75z'
	]
};